import React from "react";
import {
  Box,
  VStack,
  Text,
  Image,
  Button,
  HStack,
  Flex,
  AccordionItem,
  AccordionPanel,
  TabPanel,
  AccordionButton,
  AccordionIcon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  SimpleGrid,
  Accordion,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

const Sidebar = ({
  selectedPart,
  setSelectedPart,
  buildings,
  handleBuildingClick,
  handleBackToList,
  isRoomDetailsOpen,
  selectedRoom,
  handleRoomDetails,
  onCloseRoomDetails,
}) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      m={3}
      width="390px"
      height="90vh"
      bg="#f7faf5"
      color="rgba(82, 94, 112, 1)"
      py={4}
      px={0}
      borderRadius="10"
      overflowY="auto"
      boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none',  // IE and Edge
        'scrollbar-width': 'none',     // Firefox
      }}
    >
      {!selectedPart ? (
        <VStack align="start" spacing={4}>
          <HStack spacing={1} alignItems="center">
            <Image src="/Dartmouth-Logo.png" width="50px" />
            <VStack align="start" spacing={0}>
              <Text fontSize="md" fontWeight="bold">
                Dartmouth Housing
              </Text>
              <Text fontSize="md" color="gray.400">
                5 Residence Halls
              </Text>
            </VStack>
          </HStack>
          {buildings.map((building, index) => (
            <Box
              key={index}
              onClick={() => handleBuildingClick(building)}
              cursor="pointer"
              width="full"
              border="1px"
              borderColor="rgba(235, 241, 245, 1)"
              p={2}
              borderRadius="md"
            >
              <HStack spacing={4} justifyContent="space-between">
                <HStack spacing={4}>
                  <Image
                    src={building.imageUrl}
                    alt={building.name}
                    borderRadius="md"
                    width="60px"
                    height="60px"
                    objectFit="cover"
                  />
                  <VStack align="start" spacing={0}>
                    <Text fontSize="sm" color="gray.500">
                      {building.campus}
                    </Text>
                    <Text fontSize="md" fontWeight="bold">
                      {building.name}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      {building.description}
                    </Text>
                  </VStack>
                </HStack>
                <ChevronRightIcon w={6} h={6} color="gray.500" />
              </HStack>
            </Box>
          ))}
        </VStack>
      ) : (
        <VStack align="start" spacing={4} width="95%" margin="auto">
          <HStack width="100%" justifyContent="space-between" p={2}>
            <Button
              p={0}
              onClick={handleBackToList}
              leftIcon={
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M15 18l-6-6 6-6" />
                </svg>
              }
              iconSpacing={-5}
              colorScheme="teal"
              variant="ghost"
            />
            <Flex alignItems="center" justifyContent="center">
              <Box>
                <Text fontSize="sm" color="gray.500">
                  Main Campus
                </Text>
                <Text fontSize="md" fontWeight="bold">
                  {selectedPart}
                </Text>
              </Box>
            </Flex>
            <Image
              src={buildings.find((b) => b.name === selectedPart)?.imageUrl}
              boxSize="100px"
              borderRadius="md"
              objectFit="cover"
            />
          </HStack>

          <Tabs variant="unstyled" width="100%" isFitted>
            <TabList borderRadius="12px" p={2} border="1px solid silver">
              <Tab
                _selected={{ bg: "rgba(91, 96, 106, 1)", color: "white" }}
                bg="#f7faf5"
                color="gray.500"
                borderRadius="md"
              >
                Hall Info
              </Tab>
              <Tab
                _selected={{ bg: "rgba(91, 96, 106, 1)", color: "white" }}
                bg="#f7faf5"
                color="gray.500"
                borderRadius="md"
              >
                Room Info
              </Tab>
            </TabList>

            <TabPanels>
              {/* Hall Info Tab */}
              <TabPanel>
                <Text>
                  {buildings.find((b) => b.name === selectedPart).description}
                </Text>
                <Box mt={4} borderRadius="md" p={4}>
                  {[
                    {
                      label: "Occupancy",
                      value: buildings.find((b) => b.name === selectedPart)
                        .occupancy,
                    },
                    {
                      label: "Room Types",
                      value: buildings.find((b) => b.name === selectedPart)
                        .roomTypes,
                    },
                    {
                      label: "Floor Layout",
                      value: buildings.find((b) => b.name === selectedPart)
                        .floorLayout,
                    },
                    {
                      label: "Academic Level",
                      value: buildings.find((b) => b.name === selectedPart)
                        .academicLevel,
                    },
                    {
                      label: "Bathroom Type",
                      value: buildings.find((b) => b.name === selectedPart)
                        .bathroomType,
                    },
                    {
                      label: "Kitchen",
                      value: buildings.find((b) => b.name === selectedPart)
                        .kitchen,
                    },
                    {
                      label: "Storage",
                      value: buildings.find((b) => b.name === selectedPart)
                        .storage,
                    },
                  ].map((item, index, arr) => (
                    <React.Fragment key={index}>
                      <Flex justifyContent="space-between" py={2}>
                        <Text fontSize="small" fontWeight="bold">
                          {item.label}
                        </Text>
                        <Text fontSize="small">{item.value}</Text>
                      </Flex>
                      {index < arr.length - 1 && (
                        <Divider borderColor="gray.300" />
                      )}
                    </React.Fragment>
                  ))}
                </Box>

                <Accordion
                  defaultIndex={[1]}
                  allowMultiple
                  width="100%"
                  mt={4}
                >
                  <AccordionItem
                    bg="white"
                    boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                    border="none"
                    borderRadius="md"
                    mb={4}
                  >
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Features & Amenities
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {Object.entries(
                        buildings.find((b) => b.name === selectedPart)
                          .featuresAmenities
                      ).map(([key, value], index, arr) => (
                        <React.Fragment key={index}>
                          <Flex justifyContent="space-between" py={2}>
                            <Text fontSize="small" fontWeight="bold">
                              {key}
                            </Text>
                            <Text fontSize="small">{value}</Text>
                          </Flex>
                          {index < arr.length - 1 && (
                            <Divider borderColor="gray.300" />
                          )}
                        </React.Fragment>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    bg="white"
                    boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                    border="none"
                    borderRadius="md"
                    mb={4}
                  >
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Photos & Videos
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <SimpleGrid columns={2} spacing={4}>
                        <Image
                          src={
                            buildings.find((b) => b.name === selectedPart)
                              ?.imageUrl
                          }
                          alt="Photo 1"
                        />
                        <Image
                          src={
                            buildings.find((b) => b.name === selectedPart)
                              ?.imageUrl
                          }
                          alt="Video 1"
                        />
                      </SimpleGrid>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem
                    bg="white"
                    boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                    border="none"
                    borderRadius="md"
                    mb={4}
                  >
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Nearby
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {/* Nearby Content */}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </TabPanel>

              {/* Room Info Tab */}
              <TabPanel>
                {buildings
                  .find((b) => b.name === selectedPart)
                  .rooms.map((room, index, arr) => (
                    <React.Fragment key={index}>
                      <Box overflow="hidden" mb={4}>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          p={4}
                        >
                          <Text fontWeight="bold" fontSize="lg">
                            {room.title}
                          </Text>
                          <Button
                            rightIcon={
                              <ChevronDownIcon color="rgba(7, 131, 235, 1)" />
                            }
                            onClick={() => handleRoomDetails(room)}
                            color="rgba(7, 131, 235, 1)"
                            bg="transparent"
                            fontSize="sm"
                          >
                            Room Details
                          </Button>
                        </Flex>
                        <Box
                          position="relative"
                          borderRadius="lg"
                          overflow="hidden"
                        >
                          <Image
                            src={room.imageUrl}
                            alt={room.title}
                            width="100%"
                            borderRadius="lg"
                          />
                          <Button
                            position="absolute"
                            bottom="4"
                            left="4"
                            bg="white"
                            color="rgba(7, 131, 235, 1)"
                            size="sm"
                            borderRadius="full"
                          >
                            View Dimensions
                          </Button>
                          <Button
                            as="a"
                            href={room.roomLink} 
                            position="absolute"
                            bottom="4"
                            right="4"
                            bg="white"
                            color="rgba(7, 131, 235, 1)"
                            size="sm"
                            borderRadius="full"
                          >
                            View Room
                          </Button>
                        </Box>
                      </Box>
                      {index < arr.length - 1 && (
                        <Divider borderColor="gray.300" />
                      )}
                    </React.Fragment>
                  ))}

                {/* Modal for Room Details */}
                {isRoomDetailsOpen && (
                  <Modal
                    isOpen={isRoomDetailsOpen}
                    onClose={onCloseRoomDetails}
                    size="xl"
                 
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>{selectedRoom.title}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <HStack>
                            <VStack>
                        <Image
                          src={selectedRoom.floorPlanUrl}
                          alt="Floor Plan"
                          width="lg"
                        />
                        <Text>Dimensions</Text>
                        </VStack>
                        <VStack>
                         <Image
                          src={selectedRoom.dimensionsUrl}
                          alt="Dimensions"
                          width="lg"
                        />
                         <Text>With Included Furniture</Text>
                        </VStack>
                        </HStack>
                        <Flex
                          mt={4}
                          bg="white"
                          borderRadius="lg"
                          p={4}
                          alignItems="center"
                          justifyContent="space-between"
                          boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                        >
                          <Box>
                            <Text fontSize="md" color="black">
                              See the room in 3D
                            </Text>
                            <Text fontSize="md" color="black">
                              Take custom measurements
                            </Text>
                          </Box>
                          <Button
                            as="a"
                            href={selectedRoom.roomLink}
                            borderRadius="full"
                            bg="rgba(7, 131, 235, 1)"
                            color="white"
                            boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.15)"
                          >
                            View Room
                          </Button>
                        </Flex>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )}
    </Box>
  );
};

export default Sidebar;
